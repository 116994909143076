// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import * as Sentry from "@sentry/gatsby"
import { navigate } from "gatsby"
import styled from "styled-components"

import {
  Heading,
  Loading,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import {
  CheckCircle,
  CloseCircle,
  Ticket,
} from "@kiwicom/orbit-components/icons"

import { EmberApiError, getApologyMessageForHttpCode } from "api/errors"
import { fetchOrder } from "api/orders"

import PassTile from "components/pass-tile"

import { POLL_EXHAUSTED_ERROR, PollingConfig, usePolling } from "logic/polling"

import { Order } from "types/order"
import { PassStatus } from "types/pass"
import { UserGroup } from "types/person"

import { useGlobalState } from "utils/state-utils"
import { paramIsSetToTrue } from "utils/string-utils"
import { useQueryString } from "utils/url-utils"

// This constant is a function so that it can be easily mocked in tests
// istanbul ignore next: we mock this in tests
export const getPollingConfig = (): PollingConfig => ({
  maxPollingCalls: 300,
  interval: 1000,
})

const TopMessageWrapper = styled.div`
  > div {
    margin: 30px auto;
    max-width: 600px;
    text-align: center;
  }
`

const IconWrapper = styled.div`
  > svg {
    width: 80px;
    height: 80px;
  }
  color: ${(props) => props.theme.ember.brandColor};
`

type TopMessageType = {
  icon: React.ReactNode
  heading: string
  subheading: string | React.ReactNode
  dataTest?: string
}

const TopMessage = ({
  icon,
  heading,
  subheading,
  dataTest,
}: TopMessageType) => (
  <TopMessageWrapper>
    <Stack align="center" direction="column" dataTest={dataTest}>
      <IconWrapper>{icon}</IconWrapper>
      <Heading align="center">{heading}</Heading>
      <Text align="center">{subheading}</Text>
    </Stack>
  </TopMessageWrapper>
)

const ConfirmationPage = ({ location, suppliedOrderId = null }) => {
  const { initialOrder, initialOrderUid, groups } = useGlobalState()
  const [refreshOrderCount, setRefreshOrderCount] = useState<number>(0)
  const [orderId, setOrderId] = useState<string>(null)
  const [order, setOrder] = useState<Order>(null)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>(null)
  const [errorDetailsMsg, setErrorDetailsMsg] = useState<string>(null)
  const [waitForQueryParam, setWaitForQueryParam] = useState<boolean>(true)

  // If `initialOrder` is non-null, it means we've just navigated from the
  // checkout page, the user has just paid for the order, and we're waiting for
  // Stripe to approve or deny the payment.
  const waitForPayment = !!initialOrder

  const { queryString } = useQueryString()

  useEffect(() => {
    let params = location.state
    if (!params?.order) {
      const searchParams = new URLSearchParams(window.location.search)
      params = {
        order: searchParams.get("order"),
        confirm: paramIsSetToTrue(searchParams.get("confirm")),
      }
    }

    const urlOrderId = params.order
    let storedConfirmPageParams = null
    try {
      storedConfirmPageParams = window.localStorage.getItem("confirmPageParams")
    } catch (error) {
      console.error(error)
    }
    if (!urlOrderId && waitForQueryParam) {
      setTimeout(() => setWaitForQueryParam(false), 400)
    } else {
      if (suppliedOrderId) {
        setOrderId(suppliedOrderId)
      }
      if (
        storedConfirmPageParams == null &&
        urlOrderId == null &&
        orderId == null &&
        suppliedOrderId == null
      ) {
        setErrorMsg("Error. Did you click the correct link?")
        if (initialOrderUid) {
          Sentry.addBreadcrumb({
            category: "info",
            message: initialOrderUid,
            level: "info",
          })
        }
        Sentry.captureException(new Error("Used broken link in confirm page"))
      } else if (urlOrderId !== orderId) {
        setOrderId(urlOrderId)
      }
      if (params.confirm) {
        setConfirm(true)
      }
      if (
        storedConfirmPageParams !== null &&
        orderId == null &&
        urlOrderId == null &&
        suppliedOrderId == null
      ) {
        Sentry.captureException(
          new Error(`used params from local storage ${storedConfirmPageParams}`)
        )
        window.localStorage.removeItem("confirmPageParams")
        navigate(`/confirm/?${storedConfirmPageParams}`)
      }
    }
  }, [queryString, waitForQueryParam])

  const poll = usePolling<Order>({
    config: getPollingConfig(),

    // this will be called repeatedly
    poll: ({ onSuccess, onError }) =>
      fetchOrder({
        orderId,
        options: waitForPayment ? { poll: true } : {},
        onSuccess,
        onError,
      }),

    // we stop polling once this evaluates to `true`
    isComplete: (order: Order): boolean =>
      !waitForPayment ||
      order.passes.every((p) => p.status != PassStatus.INCOMPLETE),

    // once polling is completed, we run this once
    onSuccess: (order: Order) => {
      if (!order.passes) {
        setErrorMsg("There are no tickets in your order")
        setOrder(null)
      } else if (
        order.passes.some((p) => p.status == PassStatus.PAYMENT_FAILED)
      ) {
        setErrorMsg("Payment was not collected for this order")
        setErrorDetailsMsg(
          "Try booking again. If you're still having problems, contact us"
        )
        setOrder(null)
      } else {
        setOrder(order)
      }
    },

    // if while polling we encounter an error, we stop polling and call this
    onError: (error: EmberApiError) => {
      if (error.type == POLL_EXHAUSTED_ERROR) {
        setErrorMsg("Could not confirm payment")
      } else if (error.httpStatusCode == 400) {
        setErrorMsg(
          error.message == "InvalidUUID" || error.type == "InvalidUUID"
            ? "Error. There is a typo in your link"
            : "There was an error fetching your order"
        )
      } else {
        setErrorMsg(
          getApologyMessageForHttpCode(error.httpStatusCode) ||
            "Your order could not be found"
        )
      }
      setOrder(null)
    },
  })

  useEffect(() => {
    if (orderId != null) {
      poll.start()
    }
  }, [orderId, refreshOrderCount])

  return (
    <Stack spacing="XXLarge" align="center" direction="column">
      {order != null ? (
        <>
          {confirm ? (
            <TopMessage
              dataTest="order-top-message-confirm"
              icon={<CheckCircle />}
              heading={`You're going to ${
                order.passes[0].legs[order.passes[0].legs.length - 1]
                  .destination.regionName
              }`}
              subheading="Your tickets are available to download below and we've also emailed you a copy. You can share your booking details with someone else by sending a link to this page."
            />
          ) : (
            <TopMessage
              dataTest="order-top-message-details"
              icon={<Ticket />}
              heading={"Order details"}
              subheading="Your order details are available below. You can share this page with someone else by sending them a link."
            />
          )}
          {order.passes.map((pass) => (
            <PassTile
              key={pass.code}
              pass={pass}
              orderId={orderId}
              order={order}
              customerId={order.customerUid}
              showViewOrder={false}
              handlePassUpdate={() =>
                setRefreshOrderCount(refreshOrderCount + 1)
              }
              adminUser={
                groups &&
                (groups.includes(UserGroup.ADMINS) ||
                  groups.includes(UserGroup.OPERATIONS))
              }
            />
          ))}
        </>
      ) : errorMsg != null ? (
        <TopMessage
          dataTest="order-error"
          icon={<CloseCircle color="critical" />}
          heading={errorMsg}
          subheading={
            <>
              {errorDetailsMsg
                ? errorDetailsMsg
                : "If your order went through, your tickets should have been sent by email. If not,"}{" "}
              contact us at{" "}
              <TextLink type="secondary" href="mailto:ride@ember.to">
                ride@ember.to
              </TextLink>{" "}
              so we can investigate the issue.
            </>
          }
        />
      ) : (
        <Loading
          type="pageLoader"
          text="Loading your tickets..."
          dataTest="loading-order"
        />
      )}
    </Stack>
  )
}

export default ConfirmationPage
