/* istanbul ignore next */

/* (we don't test this, we test the `ConfirmationPage` component directly) */
import React from "react"

import ConfirmationPage from "components/confirm"
import Container from "components/container"
import Layout from "components/layout"

const ConfirmationPageWrapper = (params) => {
  return (
    <Layout title="Booking Confirmation" accountPage={true} requireState={true}>
      <Container>
        {/*
            ConfirmationPage is a separate component to simplify component
            testing. Jest gets confused by the Gatsby black magic that the SEO
            module invokes.
        */}
        <ConfirmationPage {...params} />
      </Container>
    </Layout>
  )
}

export default ConfirmationPageWrapper
